/* eslint-disable import/no-cycle */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["downloadInvoicePdfPrivate"] }] */
import { action, makeObservable, observable, runInAction } from 'mobx';
import { saveAs } from 'file-saver';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';
import {
  InvoiceInfo,
  InvoiceInfoPaginatedList,
  InvoiceService,
  NotificationService,
  PaymentCardPaymentInfo,
  PaymentCardPaymentInfoPaginatedList,
  TransactionService
} from '../swagger/api';

export enum PaymentTab {
  InvoiceTab = 1,
  CardPaymentTab = 2
}

export default class PaymentStore extends BaseStore {
  invoices: InvoiceInfo[] = [];

  cardPayments: PaymentCardPaymentInfo[] = [];

  pageSize: number = 0;

  numberOfInvoices: number = 0;

  filteredNumberOfInvoices: number = 0;

  numberOfCardPayments: number = 0;

  filteredNumberOfCardPayments: number = 0;

  hasInvoices: boolean = false;

  hasCardPayments: boolean = false;

  invoicePageNumber: number = 1;

  cardPaymentPageNumber: number = 1;

  activeTab: number = 1;

  amountOfMissingInvoicePayments: number = 0;

  setHasInvoicesToTrue = () => {
    this.hasInvoices = true;
  };

  setHasCardPaymentstoTrue = () => {
    this.hasCardPayments = true;
  };

  setInvoicePageNumber = (pageNumber: number) => {
    this.invoicePageNumber = pageNumber;
  };

  setCardPageNumber = (pageNumber: number) => {
    this.cardPaymentPageNumber = pageNumber;
  };

  setPaymentTab = (activeTab: number) => {
    this.activeTab = activeTab;
  };

  getInvoices = () => {
    this.rootStore.filterStore.invoiceFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.invoiceFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = InvoiceService.getInvoiceInfo(params);
    promise.then((val: InvoiceInfoPaginatedList) => {
      runInAction(() => {
        this.invoices = val.paginatedItems!;
        this.pageSize = val.pageSize!;
        this.numberOfInvoices = val.totalCount!;
        this.filteredNumberOfInvoices = val.filteredCount!;
      });
      if (this.numberOfInvoices > 0) this.setHasInvoicesToTrue();
    });

    return promise;
  };

  getCardPayments = () => {
    this.rootStore.filterStore.cardPaymentFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.cardPaymentFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = InvoiceService.getCardPaymentsByAccountIdAsync(params);
    promise.then((val: PaymentCardPaymentInfoPaginatedList) => {
      runInAction(() => {
        this.cardPayments = val.paginatedItems!;
        this.pageSize = val.pageSize!;
        this.numberOfCardPayments = val.totalCount!;
        this.filteredNumberOfCardPayments = val.filteredCount!;
      });
      if (this.numberOfCardPayments > 0) this.setHasCardPaymentstoTrue();
    });

    return promise;
  };

  downloadInvoicePdfPrivate = (invoiceId: string, invoiceNumber: string) => {
    const invoiceName = this.rootStore.contentStore.cmsStore.getContent('Invoice')?.content;
    const promise = InvoiceService.getInvoicePdf({ invoiceId }, { responseType: 'blob' }).then(
      (response: any) => {
        saveAs(response, `${invoiceName} ${invoiceNumber}.pdf`);
      }
    );
    return promise;
  };

  downloadInvoicePdf = (invoiceId: string, invoiceNumber: string) => {
    const params = {
      accountId: this.rootStore.userStore.currentAccount?.id!,
      invoiceId
    };
    const invoiceName = this.rootStore.contentStore.cmsStore.getContent('Invoice')?.content;
    const promise = InvoiceService.downloadInvoice(params, { responseType: 'blob' });
    promise.then((response: any) => {
      saveAs(response, `${invoiceName} ${invoiceNumber}.pdf`);
    });
  };

  downloadInvoiceCSV = (invoiceId: string, invoiceNumber: string) => {
    const params = {
      accountId: this.rootStore.userStore.currentAccount?.id!,
      invoiceId
    };
    const invoiceName = this.rootStore.contentStore.cmsStore.getContent('Invoice')?.content;
    const promise = TransactionService.downloadTransactionsForInvoicePeriodAsCsv(params, {
      responseType: 'blob'
    });
    promise.then((response: any) => {
      saveAs(response, `${invoiceName} ${invoiceNumber}.csv`);
    });
  };

  downloadReminderLetter = (dunningId: string) => {
    const dunningNoticeNumberParams = {
      accountId: this.rootStore.userStore.currentAccount?.id!,
      dunningId
    };
    const reminderLetterName =
      this.rootStore.contentStore.cmsStore.getContent('ReminderLetter')?.content;
    const dunningNoticeNumberPromise =
      InvoiceService.getDunningNoticeNumberFromLatestDunningStepByDunningId(
        dunningNoticeNumberParams
      );
    dunningNoticeNumberPromise.then((dunningNoticeNumber) => {
      const reminderLetterPdfParams = {
        accountId: this.rootStore.userStore.currentAccount?.id!,
        dunningNoticeNumber
      };
      const promise = InvoiceService.downloadReminderLetter(reminderLetterPdfParams, {
        responseType: 'blob'
      });
      promise.then((response: any) => {
        saveAs(response, `${reminderLetterName} ${dunningNoticeNumber}.pdf`);
      });
    });
  };

  getAmountOfMissingInvoicePayments = () => {
    const promise = NotificationService.getMissingPaymentsCount({
      accountId: this.rootStore.userStore.currentAccount?.id!
    });
    promise.then((result) => {
      this.amountOfMissingInvoicePayments = result;
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      invoices: observable,
      cardPayments: observable,
      numberOfInvoices: observable,
      filteredNumberOfInvoices: observable,
      numberOfCardPayments: observable,
      filteredNumberOfCardPayments: observable,
      invoicePageNumber: observable,
      cardPaymentPageNumber: observable,
      hasInvoices: observable,
      hasCardPayments: observable,
      amountOfMissingInvoicePayments: observable,
      setHasInvoicesToTrue: action,
      setHasCardPaymentstoTrue: action,
      activeTab: observable,
      getInvoices: action,
      setInvoicePageNumber: action,
      setCardPageNumber: action,
      setPaymentTab: action,
      downloadInvoicePdf: action,
      downloadInvoicePdfPrivate: action,
      downloadReminderLetter: action,
      downloadInvoiceCSV: action
    });
  }
}
