/* eslint-disable import/no-cycle */
import React from 'react';
import axios, { AxiosError } from 'axios';
import { action, makeObservable } from 'mobx';
import { RootStore } from './RootStore';
import BaseStore from './base-stores/BaseStore';
import ErrorModal from '../features/modals/ErrorModal';

export default class ErrorStore extends BaseStore {
  customHandledErrors: string[] = ['vehicleNotFoundErrorCode', 'NoIdentifierFoundByPartner'];

  handleError = (error: AxiosError) => {
    const errorHeader = this.rootStore.contentStore.cmsStore.getErrorMessage('SomethingWentWrong')!;
    const contentErrorMessage = this.rootStore.contentStore.cmsStore.getErrorMessage(
      error.response?.data.errorCode
    );
    if (
      !this.customHandledErrors.includes(error.response?.data.errorCode) &&
      !axios.isCancel(error)
    ) {
      const errorMessage =
        contentErrorMessage ||
        this.rootStore.contentStore.cmsStore.getErrorMessage('DefaultErrorMessage');
      this.rootStore.modalStore.openModal(
        <ErrorModal header={errorHeader} message={errorMessage!} />
      );
    }
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      handleError: action
    });
  }
}
